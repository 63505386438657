<template>
  <div class="InfoWrap com-mouse-default">
    <div class="priceArea">
      <div class="priceArea1 com-flex">
        <div class="com-flex" style="display: flex; align-items: center">
          <div class="title" style="font-weight: 900; font-size: 22px">
            {{ info.cinvCode }}
          </div>
        </div>
      </div>
    </div>
    <div class="goodsDetail">
      <h3 class="InfoName" style="font-size: 18px; font-weight: 500">
        {{ info.brandName + "-" + info.shoppingMallName}}
      </h3>

      <div class="priceArea" style="border-bottom: 1px solid #ededed">
        <div class="priceArea1 com-flex">
          <div class="com-flex" style="display: flex; align-items: center">
            <div class="title" style="font-weight: 500; font-size: 18px">单件价格</div>
            <div class="price" style="display: flex">
              <div style="font-size: 18px; font-weight: 500">￥</div>
              <span style="font-size: 18px; font-weight: 500; margin-bottom: 10px">
                {{ infoCopy.price }}</span
              >
            </div>
            <!-- <div class="remark" style="margin-left: 20px;">
  
                <i>月销量:768箱</i>
              </div> -->
          </div>
        </div>
      </div>
      <div class="support">
        <div class="supportArea" style="display: flex">
          <div class="title" style="font-size: 18px; font-weight: 500; width: 20%">
            剩余库存：
          </div>
          <div class="fixWidth" style="font-size: 18px; font-weight: 500">
            {{ parseInt(info.totalQuantity / info.packingQuantity) }}箱({{
              info.packingQuantity
            }}件/箱)
          </div>
        </div>
      </div>
    </div>

    <!-- 属性列表 -->
    <div class="choose">
      <div class="cartWrap">
        <div class="controls com-flex">
          <div style="border-bottom: 1px solid #ededed">
            <div style="margin-bottom: 10px; font-size: 18px; font-weight: 500">
              装箱配比：
            </div>
            <div>
              <div style="display: flex; flex-wrap: wrap">
                <div
                  v-for="(item, index) in colorList"
                  :key="index"
                  style="
                    display: flex;
                    aligin-item: center;
                    flex-direction: column;
                    margin-right: 10px;
                    margin-bottom: 20px;
                  "
                >
                  <el-image
                    style="
                      width: 100px;
                      height: 100px;
                      margin-right: 10px;
                      margin-bottom: 10px;
                    "
                    :src="item.imageUrl"
                    :preview-src-list="item.imageUrlList"
                  >
                  </el-image>
                  <div style="width: 100%; justify-content: center; align-items: center">
                    <div class="counters">
                      <div style="font-size: 21px">-</div>
                      <div style="font-weight: 500">{{ item.fpeiBi }}</div>
                      <div style="font-size: 21px">+</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="margin-bottom: 10px; line-height: 40px">购买箱数：</div>
            <div style="width: 100%; justify-content: center">
              <div class="counter">
                <div @click="decrement" style="font-size: 35px; margin-left: 20px">-</div>
                <div style="font-weight: 500">{{ buyCount }}</div>
                <div @click="increment" style="font-size: 35px; margin-right: 20px">
                  +
                </div>
              </div>
            </div>
            <div style="width: 100%; justify-content: center; margin-top: 20px">
              <div
                class="counter1"
                style="
                  font-weight: 500;
                  background: black;
                  color: white;
                  text-align: center;
                  justify-content: center;
                "
                @click="editCart()"
              >
                <div>确认修改</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
            style="
              width: 100%;
              min-height: 40px;
              background: #f8f8f8;
              margin-bottom: 15px;
              line-height: 40px;
              display: flex;
            "
          >
            <div style="margin-left: 20px; margin-right: 50px">
              {{ skuNum10 * 24 }}件/{{ skuNum10 }}箱
            </div>
            <div>
              总价格：<span style="color: #ff4400">￥{{ skuNum10 * 899 }}</span>
            </div>
            <div style="margin-left: 20px">
              体积：<span style="color: #ff4400">{{ skuNum10 * 5 }}m³</span>
            </div>
          </div> -->
        <!-- <div class="add com-mouse-point">
            <a class="addCart buy" @click="addCarts">立即购买</a>
            <a class="addCart" @click="addCart"
              ><span class="iconfont icon-gouwuche-tianchong"></span> 加入购物车</a
            >
          </div> -->
      </div>
    </div>
    <el-dialog
      title="购物车存在订单详情"
      :visible.sync="dialogTableVisible"
      width="1400px"
      append-to-body
    >
      <shopCarts ref="shopCarts"></shopCarts>
    </el-dialog>
    <el-dialog
      title="支付详情"
      :visible.sync="dialogTableVisibles"
      width="550px"
      append-to-body
    >
      <div style="display: flex">
        <div>
          <div style="margin-left: 31px">支付宝支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
        <div>
          <div style="margin-left: 31px">微信支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <el-button type="success" @click="dialogTableVisiblea = true"
          >上传支付凭证</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="上传凭证"
      :visible.sync="dialogTableVisiblea"
      width="550px"
      append-to-body
    >
      <el-upload action="#" list-type="picture-card" :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </el-dialog>
    <el-dialog title="下单详情" :visible.sync="dialogTableVisiblep" width="1400px">
      <shopOrder ref="shopCarts"></shopOrder>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisiblep" width="30%" append-to-body>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <i class="el-icon-shopping-bag-2" style="font-size: 30px"></i>
        <div style="font-size: 20px">此购物车货品已修改</div>
        <div
          style="
            width: 100%;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
          "
        >
          <div
            class="counter1"
            style="
              font-weight: 500;
              background: black;
              color: white;
              text-align: center;
              justify-content: center;
            "
            @click="goToCart()"
          >
            <div>查看购物车</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDcBasearchiveCommodityPackingratio } from "@/api/good/index.js";
import { addDcShoppingmallShoppingcart,editDcShoppingmallShoppingcart } from "@/api/cart/index.js";

import shopOrder from "../shopOrder/index.vue";
import shopCarts from "../shopCarts/index.vue";

export default {
  name: "InfoDetail",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    shopCarts,
    shopOrder,
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      dialogTableVisible: false,
      dialogVisiblep: false,
      buyCount: 0,
      dialogTableVisiblea: false,
      dialogTableVisibles: false,
      dialogTableVisiblep: false,
      skuNum: 2,
      skuNum1: 3,
      skuNum2: 5,
      skuNum3: 7,
      skuNum4: 3,
      skuNum5: 4,
      skuNum10: 1,
      skuDesc: "全场正品 达人推荐 无忧退换",
      colorList: [],
    };
  },
  created() {
    
    getDcBasearchiveCommodityPackingratio({
      cInvCode: this.$route.query.cinvCode,
      pageNum: 1,
      pageSize: 100,
    }).then((res) => {
      console.log(res);
      this.colorList = res.data;
      this.colorList.forEach((item) => {
        item.imageUrl = this.$locationUrl + "file/" + item.invImageFileUrl;
        item.imageUrlList = [item.imageUrl];
        console.log(item.imageUrl);
      });
      console.log(this.currentItems);
    });
  },
  methods: {
    goToCart() {
      window.open(this.$locationViewUrl + "#/goods/goodsCart?brandName="+this.brandName, "_blank");
    },
    decrement() {
      if (this.buyCount <= 1) {
        this.buyCount = 1;
        return;
      }
      this.buyCount--;
    },
    increment() {
      this.buyCount++;
    },
    editCart() {
        console.log(this.info)
      let shoppingCartDeatailList = [];
      this.colorList.forEach((item) => {
        shoppingCartDeatailList.push({
          colorName: item.pcolorName,
          peibiId: item.id,
          colorQuantity: item.fpeiBi,
        });
      });
      editDcShoppingmallShoppingcart({
        picePrice: this.infoCopy.price,
        totalPrice: this.info.infoCopy * this.buyCount*this.info.packingQuantity,
        quantity: this.buyCount,
        sellAttributeName: "现货",
        cinvCode: this.info.cinvCode,
        shoppingCartDeatailList: shoppingCartDeatailList,
        shoppingCartId:this.$route.query.shoppingCartId
      }).then((res) => {
        if (res.code == 200) {
          this.dialogVisiblep = true;
        }
      });
    },
    addCarts() {
      this.dialogTableVisiblep = true;
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-input-number {
//   width: 145px !important;
// }
.counter1 {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 31px;
  border-radius: 40px;
}
.counters {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 85%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 11px;
  border-radius: 40px;
}

// .counters span {
//   position: absolute;
//   left: 50%;
//   font-size: 15px;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }

// .counters::before,
// .counters::after {
//   content: "";
//   cursor: pointer;
// }

// .counters::before {
//   content: "-";
//   font-size: 12px;
//   margin-left:4px;
// }

// .counters::after {
//   content: "+";
//   font-size: 12px;
//   margin-right: 4px;
// }
.counter {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 31px;
  border-radius: 40px;
}

.InfoWrap {
  width: 700px;

  .InfoName {
    font-size: 14px;
    line-height: 21px;
    margin-top: 15px;
  }

  .news {
    color: #3c3c3c;
    margin-top: 15px;
  }

  .priceArea {
    // background: #fff2e8;
    // padding: 7px;
    // margin: 13px 0;

    .priceArea1 {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;
      justify-content: space-between;

      .title {
        margin-right: 15px;
      }

      .price {
        i,
        em {
          color: black;
        }

        i {
          font-size: 16px;
        }

        em {
          font-size: 24px;
          font-weight: 700;
          margin-left: 5px;
        }

        span {
          font-size: 12px;
        }
      }

      .remark {
        em,
        i {
          text-align: center;
          display: block;
          line-height: 13px;
          height: 15px;
        }
      }
    }

    .priceArea2 {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;

      .title {
        margin-right: 15px;
        float: left;
      }

      .fixWidth {
        width: 520px;
        float: left;

        .red-bg {
          background: #f40;
          color: #fff;
          padding: 3px;
        }

        .t-gray {
          color: #999;
        }
      }
    }
  }

  .support {
    padding-bottom: 5px;

    .supportArea {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;

      .title {
        margin-right: 15px;
        float: left;
      }

      .fixWidth {
        width: 520px;
        float: left;
        color: black;
        font-weight: 600;
      }
    }
  }

  .choose {
    .chooseArea {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;

      dl {
        overflow: hidden;
        margin: 13px 0;

        dt {
          margin-right: 15px;
          float: left;
        }

        dd {
          float: left;
          margin-right: 5px;
          color: #666;
          line-height: 24px;
          padding: 2px 14px;
          border-top: 1px solid #eee;
          border-right: 1px solid #bbb;
          border-bottom: 1px solid #bbb;
          border-left: 1px solid #eee;

          &.active {
            color: green;
            border: 1px solid green;
          }
        }
      }
    }

    .cartWrap {
      .controls {
        position: relative;

        margin: 7px 0;

        .itxt {
          width: 38px;
          height: 34px;
          border: 1px solid #ddd;
          color: #555;
          border-right: 0;
          text-align: center;
          outline: none;
        }

        .plus,
        .mins {
          width: 27px;
          text-align: center;
          height: 38px;
          background: #f1f1f1;
          color: #666;
        }

        span {
          margin-right: 11px;
        }
      }

      .add {
        a {
          width: 180px;
          background-color: #f40;
          padding: 0 25px;
          font-size: 16px;
          color: #fff;
          height: 36px;
          line-height: 36px;
          text-align: center;
          display: inline-block;
          border-radius: 3px;
        }

        .buy {
          width: 134px;
          color: #e5511d;
          border-color: #f0cab6;
          background: #ffe4d0;
          margin-right: 20px;
          padding: 0;
        }
      }
    }
  }
}
</style>
